// idのような固有値を手動管理せずに、ユニークなIDを生成する
// TODO: vue3.5で実装されるらしいので、そちらに移行する
// https://twitter.com/youyuxi/status/1745379112456429688
let id = 0;
export function useUid() {
  const uid = ref("");

  onMounted(() => {
    uid.value = `gen-uid-${String(id++)}`;
  });

  return uid;
}
